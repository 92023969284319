<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">

      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>

              <div>
                <div>
                  <multiple-select :options="sellerList" :id-field="'id_employee'" ref="seller" :placeholder="this.$i18n.t('form.employee.label')" :fetchOptions="sellerFetchOptions" :value="[]" />
                </div>
              </div>

              <h6 class="mb-3 mt-5">{{ $t('kpi.volume.period') }}</h6>
              <div class="row">
                <div class="col-md-12">
                  <div class="md-form mb-0 row">
                    <div class="col-md-12">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filYear" id="year" name="year" ref="year" >
                          <option v-for="(item, index) in yearList" v-bind:key="index" :value="item" :selected="filters.filYear === item" >{{ item }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.year') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{
                        $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList" />
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <!--  Actions -->
                <div class="flexbox px-4 py-3 mb-3">
                  <div class="d-flex align-items-center">
                  </div>
                  <div class="btn-group" role="group" aria-label="false">
                    <router-link :to="{name: 'GoalNew'}" class="btn btn-outline-light  pull-right">
                      Novo
                    </router-link>
                  </div>
                </div>
                <!--  End Actions -->
                <!-- Table -->
                <!--<div v-on:click="toggleColumn">Config</div>-->
                <div class="table-responsive ">
                  <vuetable ref="vuetable"
                            :noDataTemplate="this.$i18n.t('noting to result')"
                            :api-url="goalEndpoint"
                            :fields="vTableFields"
                            :sort-order="sortOrder"
                            :css="css.table"
                            pagination-path="pagination"
                            :per-page="20"
                            :multi-sort="multiSort"
                            :append-params="filter"
                            multi-sort-key="shift"
                            :http-options="httpOptions"
                            track-by="id_process"
                            @vuetable:pagination-data="onPaginationData"
                            @vuetable:loading="onLoading"
                            @vuetable:loaded="onLoaded"
                            @vuetable:load-error="handleLoadError"
                            @vuetable:cell-clicked="onRowClicked">
                    <template slot="actions" slot-scope="props">
                      <div class="custom-actions">
                        <a class="btn btn-link"
                           v-bind:href="mountLink('GoalEdit', props.rowData)">
                          <i class="text-primary  ti-pencil font-20"></i>
                        </a>
                        <a class="btn btn-link"
                           v-on:click="onDelete(props.rowData)">
                          <i class="text-primary  ti-trash font-20"></i>
                        </a>
                      </div>
                    </template>
                  </vuetable>

                </div>
                <vuetable-pagination ref="pagination"
                                     :css="css.pagination"
                                     @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
                <!-- END Table -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable, { VuetablePagination } from 'vuetable-2'
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import MultipleSelect from '@/components/Forms/MultipleSelect.vue'

import GoalService from '@/services/GoalService'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

import EmployeeService from '@/services/EmployeeService'

export default {
  name: 'GoalList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('basic.goal.title') + ' - %s'
    }
  },
  data () {
    return {
      sellerList: [],
      isLoading: false,
      fullPage: true,
      selectedProcess: null,
      processDetail: false,
      goalEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/goal',
      multiSort: true,
      activeFilterList: {
        begin_date: null,
        end_date: null
      },
      filter: {
      },
      fieldsTable: [
        {
          name: 'year',
          title: this.$i18n.t('form.goal.year'),
          sortField: 'year',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'employee',
          title: this.$i18n.t('form.goal.employee'),
          sortField: 'employee',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'actions',
          title: 'Ações',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center'
        }
      ],
      sortOrder: [
        { field: 'year', direction: 'desc' },
        { field: 'employee', direction: 'asc' }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    FilterSideBar,
    FilterContainer,
    MultipleSelect,
    Vuetable,
    VuetablePagination,
    Loading
  },
  // Apply filters to local filter
  beforeMount () {
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    this.$jQuery('#arrival_begin_date, #arrival_end_date, #departure_begin_date, #departure_end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_employee: object.idEmployee, year: object.year } }).href
    },
    onDelete (goal) {
      var _this = this
      _this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja excluir esta Meta.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (e) => this.deleteGoal(goal)).then((result) => {
        if (result.value) {
          _this.$alertSwal.toast('Registro excluído com sucesso!')
        } else {
          _this.$alertSwal.toast('Não foi possível excluir o registro!', 'error')
        }

        this.$refs.vuetable.refresh()
      })
    },
    async deleteGoal (goal) {
      let _this = this
      _this.isLoading = true
      await GoalService.deleteGoalByEmployee(goal.idEmployee, goal.year).then(res => {
        if (res.httpCode === 200 && res.data.status === 'ok') {
          _this.isLoading = false
        } else {
          _this.isLoading = false
        }
      }).catch(() => {
        _this.isLoading = false
      })
    },
    buildFilters () {
      this.filter.seller = (this.filters.filSeller) ? this.filters.filSeller.map(x => (x.id_employee)) : []
      this.filter.year = (this.filters.filYear) ? this.filters.filYear : []
    },
    handleLoadError (response) {
    },
    // Loader
    applyFilter () {
      this.filters.filSeller = this.$refs.seller.value
      this.filters.filYear = this.$refs.year.value

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      this.$refs.vuetable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuichSidebar()

      this.filSeller = []
      this.filYear = []

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.$refs.vuetable.refresh()
    },
    sellerFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        EmployeeService.getSellers(filters).then(res => {
          _this.sellerList = res.data.data
          resolve(_this.sellerList)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()
      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onRowClicked (row) {
    }
  },
  computed: {
    yearList () {
      return [2019, 2020, 2021, 2022]
    },
    vTableFields () {
      return this.fieldsTable
    },
    filters () {
      return this.$store.getters.aplliedFilters || null
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>
